<script setup lang="ts">
import './assets/fonts/liveswitch/liveswitch.css'
import AppBar from '@/components/Appbar.vue'
import Navigation from '@/components/Navigation.vue'
import { ref, onMounted } from 'vue'
import { useSelfStore } from '@/store/self'
import { useMessagesStore } from '@/store/messages'

const loading = ref(true)

onMounted(() => {

  // initialize
  const selfStore = useSelfStore()
  selfStore.onUserSet(() => {
    loading.value = false
  })
  if(selfStore.isUserSet()) {
    loading.value = false
  }

  const messages = useMessagesStore()
})

const drawer = ref(true)
const menu = () => {
  drawer.value = !drawer.value
}

const sheet = ref(true)
const toggleSheet = () => {
  sheet.value = !sheet.value
}
</script>
<template>
  <v-app id="app">
    <app-bar @menu="menu"></app-bar>
    <navigation v-model="drawer"  v-if="!loading"></navigation>
    <v-main class="d-flex justify-center" v-if="!loading">
      <router-view :key="$route.fullPath"></router-view>
    </v-main>
    <v-main class="d-flex justify-center" v-else>
      <v-progress-linear
        indeterminate
        color="primary"
        style="margin:30px"
      >
      </v-progress-linear>
    </v-main>

    <!-- Bottom sheet for help context -->
    <v-bottom-sheet v-model="sheet" inset :opacity="0">
      <v-card>
        <v-card-title class="py-0">
          Product Name
        </v-card-title>
        <v-card-text class="py-0">
          <p class="my-4">The product name refers to the name of the product. For example, Microsoft Outlook would have a product name of "Outlook".</p>
          <p class="my-4">This name is displayed in the marketplace so users know what accounts are required when they connect your App.</p>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" variant="flat" @click="toggleSheet">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-bottom-sheet>
  </v-app>
</template>

<style>
  html,body,div#app {
    width: 100%;
    min-height: 100%;
    background-color: #FFF;
    font-size: 0.875rem;
  }

  input:read-only {
    cursor: default;
  }

  main .spacer{
    padding-bottom:20px;
    width:100%;
    text-align:center;
    color:#999;
  }

  .v-input .v-field--variant-solo, .v-select .v-field--variant-solo
  {
      box-shadow: none !important;
      border: 1px solid rgb(var(--v-theme-border-color));
  }

  .v-input .v-field--variant-solo .required, .v-select .v-field--variant-solo .required {
    color: rgb(var(--v-theme-blue));
  }

  .v-field--focused.v-field--variant-solo {
      border-color: rgb(var(--v-theme-blue));
  }

  .v-input--error .v-field--error.v-field--variant-solo {
      border-color: rgb(var(--v-theme-red));
      background-color: rgb(var(--v-theme-input-error-bg));
  }

  .v-card .v-card-text h1 {
      line-height: 2.5em !important;
  }

  div.v-switch > .v-input__details {
      display: none;
  }

  .required {
    color: rgb(var(--v-theme-blue));
  }

  a {
      text-decoration: none;
  }

  .v-card-actions 
  {
      gap: 8px
  }

  .v-list--nav > a.v-list-item--nav > span.v-list-item__overlay {
    background: rgb(var(--v-theme-blue));
  }

  .v-table.v-data-table {
    border-radius: 8px;
  }

  .v-btn.v-btn--variant-outlined > .v-btn__overlay {
    background-color: #FFF;
  }
  
  .text-emphasis {
    color: rgb(var(--v-theme-blue));
    font-weight: bold;
  }

  .text-overline-small {
    /* Overline - Small */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
    line-height: 13px;
    /* identical to box height, or 144% */
    letter-spacing: 0.02em;
    text-transform: uppercase;
    /* Manatee Gray/Manatee Gray */
    color: #9B9EA5;
  }
  .navigation-bar .v-list-item--nav .v-list-item-title {
    font-size: 12px;
    letter-spacing: normal;
    line-height: 1.25rem;
  }

  .v-text-field input:read-only, .v-text-field textarea:read-only {
    color: rgb(var(--v-theme-grey-darken-1));
  }

  .v-text-field input:focus:read-only,
  .v-text-field input:active:read-only,
  .v-text-field textarea:focus:read-only,
  .v-text-field textarea:active:read-only {
    color: rgb(var(--v-theme-grey-darken-1))
  }

  .v-text-field.v-input--readonly div.v-counter,
  .v-text-field.v-input--readonly textarea:read-only div.v-counter
  {
    display: none;
  }



  .v-field--focused.v-field--variant-solo:has(input:read-only),
  .v-field--focused.v-field--variant-solo:has(textarea:read-only) {
    border-color: rgb(var(--v-theme-grey-lighten-2));
    color: rgb(var(--v-theme-grey-darken-1))
  }

  .v-input .v-field--variant-solo:has(input:read-only):not(:has(.v-select__selection)), 
  .v-select .v-field--variant-solo:has(input:read-only):not(:has(.v-select__selection)),
  .v-input .v-field--variant-solo:has(textarea:read-only):not(:has(.v-select__selection)), 
  .v-select .v-field--variant-solo:has(textarea:read-only):not(:has(.v-select__selection)) {
    border-color: rgb(var(--v-theme-grey-lighten-2));
  }

  .v-input.v-input--readonly label > span.required,
  .v-input.v-input--disabled label > span.required
   { 
    display: none;
  }

  .v-bottom-navigation .v-btn__content {
    font-size: 0.688rem;
    color: rgb(var(--v-theme-bottom-nav-color));
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    line-height: 0.915rem;
  }

  .v-bottom-navigation .v-btn--selected .v-btn__content {
    color: rgb(var(--v-theme-bottom-nav-selected-color));
  }
/*
  .v-icon[class^="icon-"], .v-icon[class*=" icon-"] {
    font-family: 'liveswitch-icon-v1-3-2' !important;
  }
*/

  .v-input__details{
    padding-bottom:6px;
  }

  .default-page-card-text .v-alert{
    margin-bottom: 12px;
  }

.v-data-table .v-btn {
  width: 36px !important;
  height: 36px !important;
  min-width: 36px !important;
  padding: 0 !important;
}

.v-data-table .v-btn .v-btn__content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.v-form .v-col{
  padding-bottom:0;
}

#drawflow {
  position: relative;
  background: var(--background-color);
  background-size: 25px 25px;
  background-image:
   linear-gradient(to right, #f1f1f1 1px, transparent 1px),
   linear-gradient(to bottom, #f1f1f1 1px, transparent 1px);
}

.v-main{
  max-height: 100dvh;
}
.workflow-action-card.v-card-title  p{
  padding:8px 0;
    font-size: 0.8rem;
    line-height: 1.4rem;
    white-space: normal;
}
.step-pane .step-editor .v-card-title {
    font-size: 1.2rem;
    line-height: 2.0rem;
}

.v-text-field input:read-only, 
.v-text-field textarea:read-only{
    color:black;
}
input:disabled{
    color:black;
    opacity: 1;
}
label.v-label{
  color:black;
  opacity:1;
}
</style>
<style lang="scss">
  @use '@/styles/settings';
  
</style>