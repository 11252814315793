<script setup lang="ts">
import { useSelfStore } from '@/store/self';
import { reactive, Ref, ref, computed } from 'vue';
import { useDisplay } from 'vuetify'
import { RouteRecordNormalized, useRoute, useRouter } from 'vue-router';
    
const { smAndDown } = useDisplay();
const selfStore = useSelfStore();
const drawer = ref(false);
const expanded = ref(false);

const canViewPrompts = () => {
    return true
}
const navigationItems = reactive([
    { icon: 'icon-home-stroke', name: 'Home', to: 'home', showInBottom: 'main' },
    { icon: 'icon-component-stroke', name: 'Apps', to: 'apps-list', showInBottom: 'main' },
    { icon: 'mdi-alert-circle-outline', name: 'Errors', to: 'errors-list', showInBottom: 'main' },
    /*{ icon: 'icon-home-stroke', name: 'Workflows', to: 'workflows-list', showInBottom: 'main' },*/
    { icon: 'icon-cog-gear', name: 'Systems', to: 'systems-list', showInBottom: 'main' },
    /*{ icon: 'icon-cog-gear', name: 'Settings', to: 'settings', showInBottom: 'more', condition: canViewPrompts },*/
    /*{ icon: 'icon-cog-gear', name: 'Settings', to: 'settings-edit', condition: canViewSettings, showInBottom: 'main' },*/

]);
const bottomNavMainItems = function(){
    return navigationItems//navigationItems.filter(x=>x.showInBottom == 'main' && (x.condition ? x.condition() : true))
}
const bottomNavMoreItems = function(){
    return []//navigationItems.filter(x=>x.showInBottom == 'more' && (x.condition ? x.condition() : true))
}
const leftNavItems = function(){
    return navigationItems //navigationItems.filter(x=>x.condition ? x.condition() : true)
}

const route = useRoute();
const router = useRouter();
const isActive = (navTo: string) => {
    const navRoute = router.resolve({ name: navTo });
    if (navTo == 'home') {
        return route.path == '/';
    }
    return route.path.startsWith(navRoute.path);
};
    
</script>
<template>
    <v-navigation-drawer 
        v-if='!smAndDown' 
        app
        style="border-right:0"
        v-bind="$attrs"
        permanent 
        class="navigation-bar hidden-sm-and-down"
        :width="170">

        <v-list nav style="background-color: transparent;" open-strategy="multiple">
            <div v-for="item in leftNavItems()">
                <v-list-item density="compact"  :to="{ name: item.to }" :class="{ 'v-list-item--active': isActive(item.to) }">
                    <template v-slot:prepend>
                        <v-icon v-if="item.icon" :class="item.icon">{{ item.icon }}</v-icon>
                    </template>
                    <v-list-item-title>{{item.name}}</v-list-item-title>
                </v-list-item>
            </div>
        </v-list>
    </v-navigation-drawer>
    <v-bottom-navigation v-if="smAndDown" class="hidden-md-and-up" color="primary" active grow prominent>
        <v-btn v-for="nav in bottomNavMainItems()" class="bottom-navbar-button" :to="{ name: nav.to }">
            <v-icon>{{nav.icon}}</v-icon> {{nav.name}}
        </v-btn>
        <v-btn class="bottom-navbar-button" @click.stop="drawer = !drawer" v-if="bottomNavMoreItems().length > 0">
            <v-icon>icon-hamburger-LG</v-icon> More
        </v-btn>
    </v-bottom-navigation>
    <!--<v-navigation-drawer
        v-model="drawer"
        location="bottom"
        temporary>
        <v-list>
            <v-list-item v-for="nav in bottomNavMoreItems()" :to="{ name: nav.to }" :prependIcon="nav.icon">
                <v-list-item-title>{{nav.name}}</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>-->
</template>
<style scoped>
.navigation-bar :deep(.v-list-item__prepend){ display:block;}
.navigation-bar :deep(.v-list-group__items .v-list-item){padding-inline-start:32px !important;}
</style>