import { defineStore } from "pinia";
import { Ref, ref} from "vue";
import * as Sentry from "@sentry/browser";
import { Api, WorkflowDto, Definition, Step } from '@/types/api';
import * as signalR from "@microsoft/signalr";

export const useApiStore = defineStore('apiStore', () => {
    const apiToken : Ref<string> = ref("");
    const tenantId : Ref<string> = ref("");
    const errorMessages : Ref<Array<string>> = ref([]);
    const baseURL: string = import.meta.env.VITE_API_ROOT;
    
    const { fetch: originalFetch } = window;
    window.fetch = async (...args) => {
        let [resource, config] = args;
        let response = await originalFetch(resource as any, config);
        if (!response.ok && response.status === 401) {
            // 401 error handling
            window.setTimeout(() => {
                window.location.href = import.meta.env.VITE_API_ROOT + '/api/authentication/login'
            }, 1)
        }
        return response;
    };

    const getUrl = (path: string) => {
        return baseURL + path;
    }

    const setToken = (token: any) => {
        localStorage.setItem('access_token', token.access_token);
        localStorage.setItem('access_token_expires_in', token.expires_in);
        localStorage.setItem('access_token_expires_at', '' + (new Date().getTime() + (token.expires_in * 1000)));
        apiToken.value = token
    }

    const expiresAt = () => {
        let expiresAt = localStorage.getItem("access_token_expires_at")
        if (expiresAt) {
            return parseInt(expiresAt);
        }
        return 0;
    }

    const getToken = () => {
        let token = localStorage.getItem('access_token');
        if (token) {
            apiToken.value = token;
        }
        return apiToken.value;
    }

    const isAuthenticated = () => {
        return new Date().getTime() < 1 * expiresAt();
    }
    
    const isExpiringSoon = () => {
        // within 5m of expiration
        return new Date().getTime() > (1 * expiresAt()) - (60000 * 5);
    }

    const setTenantId = (id: string) => {
        tenantId.value = id;
    }

    const getAuthToken = () => {
        return localStorage.getItem('access_token');
    }

    const securityWorker = <SecurityDataType = unknown>(
        securityData: SecurityDataType | null
    ): any => {
        const result = { headers: {} };
        const token = getAuthToken();
        result.headers = { Authorization: 'Bearer ' + token };
        return result;
    };

    const api: Api<unknown> = new Api({
        baseUrl: import.meta.env.VITE_API_ROOT,
        securityWorker: securityWorker,
    })

    const connection = new signalR.HubConnectionBuilder()
        .withUrl(import.meta.env.VITE_API_ROOT + "/hubs/admin", {
            withCredentials: false,
            accessTokenFactory: () => getAuthToken() as string
        })
        .configureLogging(signalR.LogLevel.Information)
        .withAutomaticReconnect()
        .build();
    
    connection.start().then(function () {
        console.log("Connected");
    }).catch(function (err) {
        console.error("Error: " + err);
        return console.error(err.toString());
    });

    connection.onreconnecting((error) => {
        console.log('reconnecting', error)
    })

    connection.onreconnected((connectionId) => {
        console.log('reconnected', connectionId)
    })


    return { isExpiringSoon, setToken, getToken, setTenantId, api, getUrl, connection };
})