///authorization/roles
import { WorkflowExceptionDto, WorkflowInstance } from '@/types/api';
import { defineStore } from 'pinia'
import { computed, ref } from 'vue';
import { useApiStore } from '@/store/api';

export interface Message{
    action: string;
    workflowId: string;
    workflowInstanceId?: string;
    stepId: string;
    stepName: string;
    created: Date;
    error?: string;
    stackTrace?: string;
    meta: any;
    data: any;
}

export const useMessagesStore = defineStore('messagesStore', () =>
{   
    const apiStore = useApiStore()
    const connection = apiStore.connection
    const messages = ref([] as Message[]);

    const addMessage = (message: Message) => {
        messages.value.push(message);
    }

    const clear = () => {
        messages.value = [];
    }

    const instanceToMessage = (workflowInstance: WorkflowInstance, action: string, stepId: string = '', stepName: string = '') => {
        return {
            action: action,
            workflowId: workflowInstance.definitionId as string,
            workflowInstanceId: workflowInstance.workflowId as string,
            meta: workflowInstance.meta,
            data: workflowInstance.data,
            stepId: stepId,
            stepName: stepName,
            created: new Date()
        }
    }


    connection.on("WorkflowInstanceStepBegin", function (workflowInstance: WorkflowInstance, stepId: string) {
        let message = instanceToMessage(workflowInstance, 'stepBegin', stepId);
        addMessage(message);
        console.log('executing step', workflowInstance.workflowId, stepId)
    });
    connection.on("WorkflowInstanceStepEnd", function (workflowInstance: WorkflowInstance, stepId: string, proceed: boolean) {
        let message = instanceToMessage(workflowInstance, 'stepEnd', stepId);
        addMessage(message);
        console.log('completed step', workflowInstance.workflowId, stepId, 'proceeding? : ' + proceed)
    });

    connection.on("WorkflowInstanceBegin", function (workflowInstance: WorkflowInstance) {
        let message = instanceToMessage(workflowInstance, 'stepEnd');
        addMessage(message);
        console.log('starting workflow', workflowInstance.workflowId)
    });
    connection.on("WorkflowInstanceEnd", function (workflowInstance: WorkflowInstance) {
        let message = instanceToMessage(workflowInstance, 'instanceEnd');
        addMessage(message);
        console.log('ending workflow', workflowInstance.workflowId)
    });

    connection.on("WorkflowInstanceError", function (workflowException: WorkflowExceptionDto) {  
        addMessage({
            action: 'error',
            workflowId: workflowException.workflowId as string,
            workflowInstanceId: workflowException.workflowInstanceId as string,
            error: workflowException.message as string,
            stepName: workflowException.stepName as string,
            stackTrace: workflowException.stackTrace as string,
            stepId: workflowException.stepExternalId as string,
            created: new Date(),
            meta: workflowException.workflowMeta,
            data: workflowException.workflowData
        });
        console.log('error in workflow', workflowException)
    });
    

    return { addMessage, messages, clear }
});