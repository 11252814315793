/**
 * main.ts
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Components
import App from './App.vue'

// Composables
import { createApp } from 'vue'

// Plugins
import { registerPlugins } from '@/plugins'

import { install as VueMonacoEditorPlugin } from '@guolao/vue-monaco-editor'

import '@/styles/workflow.css'

const app = createApp(App)

app.use(VueMonacoEditorPlugin, {
    paths: {
      // You can change the CDN config to load other versions
      vs: 'https://cdn.jsdelivr.net/npm/monaco-editor@0.50.0/min/vs'
    },
})

registerPlugins(app)

app.mount('#app')
