/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
// import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Composables
import { createVuetify } from 'vuetify';
import { VBtn } from 'vuetify/components/VBtn';
import { aliases as lsAliases, ls } from '@/iconsets/liveswitch';
import { aliases as mdiAliases, mdi } from 'vuetify/iconsets/mdi'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

import { customSVGs } from './svgIcons';

const aliases = {...lsAliases, ...mdiAliases}

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
      icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
          mdi,
          ls,
          svg: customSVGs
        }
      },
      aliases: {
        ActionBtn: VBtn,
        CancelBtn: VBtn,
        WizardBtn: VBtn
      },
      defaults: {
        VSelect: {
          variant: 'solo',
          elevation: 0,
          flat: true
        },
        VSnackbar: {
          timeout: "2000",
          location: "top"
        },
        VSwitch: {
          color: 'primary'
        },
        VDataTableServer: {
          elevation: 0
        },
        VTextarea: {
          elevation: 0,
          variant: 'solo',
          flat: true
        },
        VTextField: {
          variant: 'solo',
          elevation: 0,
          flat: true,
          persistentHint: false
        },
        VBtn: {
          elevation: 0
        },
        VCard: {
          elevation: 3
        },
        CancelBtn: {
          color: '#000',
          elevation: 0,
          variant: 'outlined'
        }
      },
      locale: {
        locale: 'en',
        fallback: 'en'
      },
      theme: {
        defaultTheme: 'liveswitch',
        themes: {
          liveswitch: {
            dark: false,
            colors: {
              background: '#FFF',
              surface: '#FFF',
              primary: '#0070FF',
              secondary: '#ccc',
              error: '#E33154',
              info: '#979EAF',
              success: '#00A441',
              warning: '#F19D38',
              orange: '#FB8C00',
              'orange-light': 'FFE0B2',
              indigo: '#7096FB',
              'indigo-light': 'DEE2F7',
              tertiary: '#5E6168',
              manateegray: '#9B9EA5',
              altrow: '#F3F5F9',
              headingBG: '#E5EBED',
              'input-error-bg': '#FAEEF0',
              'border-color': '#979EAF',
              'bottom-nav-color': '#707070',
              'bottom-nav-selected-color': '#09214A',
              red: '#E84867',
              'red-lighten-5': '#FAEEF0',
              'red-lighten-4': '#FDDDE3',
              'red-lighten-3': '#F9C6CF',
              'red-lighten-2': '#F1AAB7',
              'red-lighten-1': '#E17083',
              'red-darken-1': '#AC4454',
              'red-darken-2': '#80323F',
              'red-darken-3': '#6B2A35',
              'red-darken-4': '#401920',
              green: '#00BA4A',
              'green-lighten-5': '#EBF9EF',
              'green-lighten-4': '#D6F3DF',
              'green-lighten-3': '#9DE3B0',
              'green-lighten-2': '#8BDCA1',
              'green-lighten-1': '#4CBD63',
              'green-darken-1': '#3F8B47',
              'green-darken-2': '#35773D',
              'green-darken-3': '#2C6333',
              'green-darken-4': '#183C1F',
              blue: '#0070FF',
              'blue-lighten-5': '#F5F9FF',
              'blue-lighten-4': '#E5EFFE',
              'blue-lighten-3': '#B8D4FF',
              'blue-lighten-2': '#90BAFF',
              'blue-lighten-1': '#4D8BF7',
              'blue-darken-1': '#2559C5',
              'blue-darken-2': '#1F4DAD',
              'blue-darken-3': '#14377B',
              'blue-darken-4': '#09214A',
              grey: '#9E9E9E',
              'grey-lighten-5': '#F9FAFC',
              'grey-lighten-4': '#F3F5F9',
              'grey-lighten-3': '#EBEEF5',
              'grey-lighten-2': '#DDE0E7',
              'grey-lighten-1': '#BABDC4',
              'grey-darken-1': '#72757C',
              'grey-darken-2': '#5E6168',
              'grey-darken-3': '#3F4249',
              'grey-darken-4': '#1E2128',
              yellow: '#FFEB3B',
              'yellow-lighten-5': '#FFFDE7',
              'yellow-lighten-4': '#FFF9C4',
              'yellow-lighten-3': '#FFF59D',
              'yellow-lighten-2': '#FFF176',
              'yellow-lighten-1': '#FFEE58',
              'yellow-darken-1': '#FDD835',
              'yellow-darken-2': '#FBC02D',
              'yellow-darken-3': '#F9A825',
              'yellow-darken-4': '#F57F17',
              'yellow-darken-5': '#F57F17',
              purple: '#9C27B0',
              'purple-lighten-5': '#F3E5F5',
              'purple-lighten-4': '#E1BEE7',
              'purple-lighten-3': '#CE93D8',
              'purple-lighten-2': '#BA68C8',
              'purple-lighten-1': '#AB47BC',
              'purple-darken-1': '#8E24AA',
              'purple-darken-2': '#7B1FA2',
              'purple-darken-3': '#6A1B9A',
              'purple-darken-4': '#4A148C',
              'purple-darken-5': '#4A148C',
            }
          }
        }
    }
})
