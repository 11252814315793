<script setup>

const props = defineProps({
    title: { type: String, default: `Set title="your title" in the layout props` },
    subtitle: { type: String, default: ''},
    titleVisible: { default: true, type: Boolean },
    elevation: { default: 3, type: Number},
    background: { type: String, default: null },
    fillHeight: { default: false, type: Boolean }
});
</script>
<template>
    <v-container fluid class="default-page-container" :class="{ 'no-title': !props.titleVisible }">
        <div class="page" :class="{'fill-height': props.fillHeight}">
            <div class="d-flex page-header" v-if="props.titleVisible">
                <div class="page-header-title-container">
                    <h1 class="d-flex page-title v-card-title" style="position:relative">
                        {{ props.title }} 
                    </h1>
                    <h5 v-if="props.subtitle" style="margin-bottom:16px">{{ props.subtitle }}</h5>
                </div>

                <div class="d-flex title-additions" style="flex-grow:1;justify-content:end;gap:4px">
                    
                    <slot name="title-additions"></slot>
                </div>
            </div>
            <v-card class="default-page-card"
                
                :elevation="props.elevation" 
                :style="{ background: props.background || $vuetify.theme.themes.dark.background }">
                <v-card-text class="default-page-card-text" :class="{'fill-height': props.fillHeight}">
                    <slot></slot>
                </v-card-text>
            </v-card>
        </div>
    </v-container>
</template>
<style>

.title-additions button{
    display: flex;
    gap:4px;
}

.v-container.default-page-container {
    background-color: transparent;
}

.v-container.default-page-container{
    margin:0;
    padding-top:8px;
}

.nested-default-layout .v-container.default-page-container{
    /* if the default layout is inside a row/col structure, let it use auto margins to center it */
    margin:auto;
}

.v-container.default-page-container.no-title .default-page-card-text{
    padding-top: 0;
    padding-left: 3px;
}

div.page h1.page-title{
    padding-top:0;
    padding-left:0;
    font-size:2rem;
    line-height: 1em;
}
.v-btn .v-btn__content{
    text-transform: none;
}
</style>