/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AccountDto {
  name?: string | null;
  /** @format uuid */
  id?: string;
}

export interface AppDto {
  name?: string | null;
  codeName?: string | null;
  connections?: ConnectionDto[] | null;
  environment?: EnvironmentType;
  status?: PublishStatus;
  /** @format int32 */
  version?: number;
  isReadOnly?: boolean;
  description?: string | null;
  featuresAndBenefits?: string | null;
  installationSteps?: string | null;
  beforeYouStart?: string | null;
  otherResources?: string | null;
  needHelp?: string | null;
  developerName?: string | null;
  helpEmail?: string | null;
  salesEmail?: string | null;
  logoUrl?: string | null;
  isInstalled?: boolean;
  /** @format uuid */
  id?: string;
}

export enum AuthLevel {
  Tenant = "Tenant",
  User = "User",
}

export enum AuthType {
  OAuth = "OAuth",
  ApiKey = "ApiKey",
  None = "None",
}

export interface ConnectionDto {
  /** @format uuid */
  appId?: string;
  app?: AppDto;
  system?: SystemDto;
  /** @format uuid */
  systemId?: string;
  oAuthClientId?: string | null;
  oAuthClientSecret?: string | null;
  isRequired?: boolean;
  authLoginUrl?: string | null;
  authCallbackUrl?: string | null;
  oAuthScopes?: string | null;
  apiKeyValue?: string | null;
  /** @format uuid */
  id?: string;
}

export interface Definition {
  id?: string | null;
  /** @format int32 */
  version?: number;
  description?: string | null;
  defaultErrorBehavior?: WorkflowErrorHandling;
  /** @format date-span */
  defaultErrorRetryInterval?: string | null;
  steps?: Step[] | null;
}

export enum EnvironmentType {
  Development = "Development",
  Staging = "Staging",
  Production = "Production",
}

export interface MeDto {
  user?: UserDto;
  account?: AccountDto;
  tenant?: TenantDto;
  /** @format int32 */
  appCount?: number;
}

export interface OAuthSettingDto {
  authCodeExchangeUrl?: string | null;
  authCodeExchangeMethod?: string | null;
  authCodeExchangeContentType?: string | null;
  accessTokenRefreshUrl?: string | null;
  /** @format int32 */
  authCodeExpiresInSecondsOverride?: number;
  loginUrl?: string | null;
  tokenType?: string | null;
  /** @format uuid */
  id?: string;
}

export interface OrderBy {
  key?: string | null;
  order?: string | null;
}

export enum PublishStatus {
  Unpublished = "Unpublished",
  SubmittedForReview = "SubmittedForReview",
  UnderReview = "UnderReview",
  RequiresChanges = "RequiresChanges",
  Approved = "Approved",
  Released = "Released",
  Archived = "Archived",
}

export interface SelectNextStep {
  stepId?: string | null;
  condition?: string | null;
}

export interface Step {
  stepType?: string | null;
  id?: string | null;
  name?: string | null;
  cancelCondition?: string | null;
  errorBehavior?: WorkflowErrorHandling;
  /** @format date-span */
  retryInterval?: string | null;
  do?: Step[][] | null;
  compensateWith?: Step[] | null;
  saga?: boolean;
  nextStepId?: string | null;
  inputs?: Record<string, any>;
  outputs?: Record<string, string | null>;
  selectNextStep?: SelectNextStep[] | null;
}

export interface StepDefinitionDto {
  typeName?: string | null;
  displayName?: string | null;
  description?: string | null;
  hexColorCode?: string | null;
  icon?: string | null;
  isTrigger?: boolean;
  isAction?: boolean;
  inputs?: StepDefinitionPropertyDto[] | null;
  outputs?: StepDefinitionPropertyDto[] | null;
}

export interface StepDefinitionPropertyDto {
  name?: string | null;
  propertyName?: string | null;
  displayName?: string | null;
  type?: string | null;
  description?: string | null;
}

export interface SystemDto {
  logoUrl?: string | null;
  productName?: string | null;
  vendorName?: string | null;
  url?: string | null;
  oAuthSetting?: OAuthSettingDto;
  authLevel?: AuthLevel;
  authType?: AuthType;
  apiKeyHeaderName?: string | null;
  apiKeyValuePrefix?: string | null;
  isPublic?: boolean;
  isRequired?: boolean;
  codeName?: string | null;
  webhooks?: WebhookDto[] | null;
  /** @format uuid */
  id?: string;
}

export interface TenantDto {
  name?: string | null;
  /** @format uuid */
  id?: string;
}

export enum TriggerType {
  ConnectionWebhook = "ConnectionWebhook",
  CustomWebhook = "CustomWebhook",
  Schedule = "Schedule",
  Manual = "Manual",
}

export interface UserDto {
  email?: string | null;
  /** @format uuid */
  id?: string;
}

export interface WebhookDto {
  name?: string | null;
  /** @format uuid */
  id?: string;
}

export interface WorkflowDto {
  name?: string | null;
  description?: string | null;
  /** @format uuid */
  appId?: string;
  app?: AppDto;
  triggerType?: TriggerType;
  sampleTriggerData?: string | null;
  appName?: string | null;
  /** @format uuid */
  sourceSystemId?: string | null;
  sourceSystem?: SystemDto;
  sourceSystemEventName?: string | null;
  /** @format uuid */
  sourceSystemWebhookId?: string | null;
  listenerUrl?: string | null;
  definition?: Definition;
  /** @format uuid */
  id?: string;
}

export enum WorkflowErrorHandling {
  Retry = "Retry",
  Suspend = "Suspend",
  Terminate = "Terminate",
  Compensate = "Compensate",
}

export interface WorkflowExceptionDto {
  /** @format uuid */
  id?: string;
  /** @format uuid */
  appId?: string;
  /** @format date-time */
  errorTime?: string;
  /** @format date-time */
  createdAt?: string;
  /** @format uuid */
  workflowInstanceId?: string;
  /** @format uuid */
  workflowId?: string;
  workflowName?: string | null;
  stepName?: string | null;
  /** @format int32 */
  stepId?: number;
  message?: string | null;
  stackTrace?: string | null;
  stepExternalId?: string | null;
  environment?: EnvironmentType;
  workflowData?: Record<string, any>;
  workflowMeta?: Record<string, any>;
}

export interface WorkflowInstance {
  workflowId?: string | null;
  data?: Record<string, any>;
  meta?: Record<string, any>;
  definitionId?: string | null;
  /** @format int32 */
  version?: number;
  status?: string | null;
  reference?: string | null;
  /** @format date-time */
  startTime?: string;
  /** @format date-time */
  endTime?: string | null;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
              ? JSON.stringify(property)
              : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response.clone() as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title LiveSwitch.Exchange.API
 * @version 1.0
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Authentication
   * @name CallbackList
   * @request GET:/callback
   * @secure
   */
  callbackList = (
    query?: {
      code?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/callback`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });

  apps = {
    /**
     * No description
     *
     * @tags App
     * @name AppsList
     * @request GET:/api/apps
     * @secure
     */
    appsList: (params: RequestParams = {}) =>
      this.request<AppDto[], any>({
        path: `/api/apps`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags App
     * @name AppsCreate
     * @request POST:/api/apps
     * @secure
     */
    appsCreate: (data: AppDto, params: RequestParams = {}) =>
      this.request<AppDto, any>({
        path: `/api/apps`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags App
     * @name AppsDetail
     * @request GET:/api/apps/{id}
     * @secure
     */
    appsDetail: (id: string, params: RequestParams = {}) =>
      this.request<AppDto, any>({
        path: `/api/apps/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags App
     * @name AppsUpdate
     * @request PUT:/api/apps/{id}
     * @secure
     */
    appsUpdate: (id: string, data: AppDto, params: RequestParams = {}) =>
      this.request<AppDto, any>({
        path: `/api/apps/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags App
     * @name AppsDelete
     * @request DELETE:/api/apps/{id}
     * @secure
     */
    appsDelete: (id: string, params: RequestParams = {}) =>
      this.request<AppDto, any>({
        path: `/api/apps/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  appExecutions = {
    /**
     * No description
     *
     * @tags AppExecution
     * @name AppExecutionsCreate
     * @request POST:/api/app-executions
     * @secure
     */
    appExecutionsCreate: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/app-executions`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AppExecution
     * @name AppExecutionsList
     * @request GET:/api/app-executions
     * @secure
     */
    appExecutionsList: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/app-executions`,
        method: "GET",
        secure: true,
        ...params,
      }),
  };
  appversions = {
    /**
     * No description
     *
     * @tags AppVersion
     * @name AppversionsCreate
     * @request POST:/api/appversions/{id}
     * @secure
     */
    appversionsCreate: (id: string, params: RequestParams = {}) =>
      this.request<AppDto, any>({
        path: `/api/appversions/${id}`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  authentication = {
    /**
     * No description
     *
     * @tags Authentication
     * @name AuthenticationLoginList
     * @request GET:/api/authentication/login
     * @secure
     */
    authenticationLoginList: (
      query?: {
        /** @default "/api/authentication/callback" */
        returnUrl?: string;
        /** @default false */
        raw?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/authentication/login`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentication
     * @name AuthenticationLogoutList
     * @request GET:/api/authentication/logout
     * @secure
     */
    authenticationLogoutList: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/authentication/logout`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentication
     * @name AuthenticationCallbackList
     * @request GET:/api/authentication/callback
     * @secure
     */
    authenticationCallbackList: (
      query?: {
        code?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/authentication/callback`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
  };
  connections = {
    /**
     * No description
     *
     * @tags Connection
     * @name ConnectionsList
     * @request GET:/api/connections
     * @secure
     */
    connectionsList: (
      query?: {
        /** @format uuid */
        appId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ConnectionDto[], any>({
        path: `/api/connections`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Connection
     * @name ConnectionsCreate
     * @request POST:/api/connections
     * @secure
     */
    connectionsCreate: (data: ConnectionDto, params: RequestParams = {}) =>
      this.request<ConnectionDto, any>({
        path: `/api/connections`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Connection
     * @name ConnectionsDetail
     * @request GET:/api/connections/{id}
     * @secure
     */
    connectionsDetail: (id: string, params: RequestParams = {}) =>
      this.request<ConnectionDto, any>({
        path: `/api/connections/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Connection
     * @name ConnectionsUpdate
     * @request PUT:/api/connections/{id}
     * @secure
     */
    connectionsUpdate: (id: string, data: ConnectionDto, params: RequestParams = {}) =>
      this.request<ConnectionDto, any>({
        path: `/api/connections/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Connection
     * @name ConnectionsDelete
     * @request DELETE:/api/connections/{id}
     * @secure
     */
    connectionsDelete: (id: string, params: RequestParams = {}) =>
      this.request<ConnectionDto, any>({
        path: `/api/connections/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  connectionAuthentication = {
    /**
     * No description
     *
     * @tags ConnectionAuthentication
     * @name ConnectionAuthenticationOauthCallbackDetail
     * @request GET:/api/connection-authentication/oauth/callback/{connectionId}
     * @secure
     */
    connectionAuthenticationOauthCallbackDetail: (
      connectionId: string,
      query?: {
        /** @default "" */
        code?: string;
        /** @default "" */
        error?: string;
        /** @default "" */
        error_description?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/connection-authentication/oauth/callback/${connectionId}`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ConnectionAuthentication
     * @name ConnectionAuthenticationOauthCallbackCreate
     * @request POST:/api/connection-authentication/oauth/callback/{connectionId}
     * @secure
     */
    connectionAuthenticationOauthCallbackCreate: (
      connectionId: string,
      query?: {
        code?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/connection-authentication/oauth/callback/${connectionId}`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ConnectionAuthentication
     * @name ConnectionAuthenticationDetail
     * @request GET:/api/connection-authentication/{connectionId}
     * @secure
     */
    connectionAuthenticationDetail: (connectionId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/connection-authentication/${connectionId}`,
        method: "GET",
        secure: true,
        ...params,
      }),
  };
  me = {
    /**
     * No description
     *
     * @tags Me
     * @name GetMe
     * @request GET:/api/me
     * @secure
     */
    getMe: (params: RequestParams = {}) =>
      this.request<MeDto, any>({
        path: `/api/me`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Me
     * @name MeEmailList
     * @request GET:/api/me/email
     * @secure
     */
    meEmailList: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/me/email`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  publicapps = {
    /**
     * No description
     *
     * @tags PublicApp
     * @name PublicappsList
     * @request GET:/api/publicapps
     * @secure
     */
    publicappsList: (params: RequestParams = {}) =>
      this.request<AppDto[], any>({
        path: `/api/publicapps`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PublicApp
     * @name PublicappsConnectDetail
     * @request GET:/api/publicapps/connect/{appId}
     * @secure
     */
    publicappsConnectDetail: (appId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/publicapps/connect/${appId}`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PublicApp
     * @name PublicappsInstallCreate
     * @request POST:/api/publicapps/install/{appId}
     * @secure
     */
    publicappsInstallCreate: (appId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/publicapps/install/${appId}`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PublicApp
     * @name PublicappsUninstallCreate
     * @request POST:/api/publicapps/uninstall/{appId}
     * @secure
     */
    publicappsUninstallCreate: (appId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/publicapps/uninstall/${appId}`,
        method: "POST",
        secure: true,
        ...params,
      }),
  };
  status = {
    /**
     * No description
     *
     * @tags Status
     * @name StatusList
     * @request GET:/api/status
     * @secure
     */
    statusList: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/status`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Status
     * @name StatusExtendedList
     * @request GET:/api/status/extended
     * @secure
     */
    statusExtendedList: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/status/extended`,
        method: "GET",
        secure: true,
        ...params,
      }),
  };
  stepdefinitions = {
    /**
     * No description
     *
     * @tags StepDefinition
     * @name StepdefinitionsList
     * @request GET:/api/stepdefinitions
     * @secure
     */
    stepdefinitionsList: (params: RequestParams = {}) =>
      this.request<StepDefinitionDto[], any>({
        path: `/api/stepdefinitions`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  systems = {
    /**
     * No description
     *
     * @tags System
     * @name SystemsList
     * @request GET:/api/systems
     * @secure
     */
    systemsList: (
      query?: {
        include?: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<SystemDto[], any>({
        path: `/api/systems`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags System
     * @name SystemsCreate
     * @request POST:/api/systems
     * @secure
     */
    systemsCreate: (data: SystemDto, params: RequestParams = {}) =>
      this.request<SystemDto, any>({
        path: `/api/systems`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags System
     * @name SystemsDetail
     * @request GET:/api/systems/{id}
     * @secure
     */
    systemsDetail: (id: string, params: RequestParams = {}) =>
      this.request<SystemDto, any>({
        path: `/api/systems/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags System
     * @name SystemsUpdate
     * @request PUT:/api/systems/{id}
     * @secure
     */
    systemsUpdate: (id: string, data: SystemDto, params: RequestParams = {}) =>
      this.request<SystemDto, any>({
        path: `/api/systems/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags System
     * @name SystemsDelete
     * @request DELETE:/api/systems/{id}
     * @secure
     */
    systemsDelete: (id: string, params: RequestParams = {}) =>
      this.request<SystemDto, any>({
        path: `/api/systems/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  triggers = {
    /**
     * No description
     *
     * @tags Trigger
     * @name TriggersCreate
     * @request POST:/api/triggers/{id}
     * @secure
     */
    triggersCreate: (
      id: string,
      data: Record<string, any>,
      query?: {
        /** @default false */
        isTesting?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<WorkflowInstance, any>({
        path: `/api/triggers/${id}`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  workflows = {
    /**
     * No description
     *
     * @tags Workflow
     * @name WorkflowsList
     * @request GET:/api/workflows
     * @secure
     */
    workflowsList: (
      query?: {
        /** @format uuid */
        appId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<WorkflowDto[], any>({
        path: `/api/workflows`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workflow
     * @name WorkflowsCreate
     * @request POST:/api/workflows
     * @secure
     */
    workflowsCreate: (data: WorkflowDto, params: RequestParams = {}) =>
      this.request<WorkflowDto, any>({
        path: `/api/workflows`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workflow
     * @name WorkflowsDetail
     * @request GET:/api/workflows/{id}
     * @secure
     */
    workflowsDetail: (id: string, params: RequestParams = {}) =>
      this.request<WorkflowDto, any>({
        path: `/api/workflows/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workflow
     * @name WorkflowsUpdate
     * @request PUT:/api/workflows/{id}
     * @secure
     */
    workflowsUpdate: (id: string, data: WorkflowDto, params: RequestParams = {}) =>
      this.request<WorkflowDto, any>({
        path: `/api/workflows/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workflow
     * @name WorkflowsDelete
     * @request DELETE:/api/workflows/{id}
     * @secure
     */
    workflowsDelete: (id: string, params: RequestParams = {}) =>
      this.request<WorkflowDto, any>({
        path: `/api/workflows/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  workflowdefinitions = {
    /**
     * No description
     *
     * @tags WorkflowDefinition
     * @name WorkflowdefinitionsDetail
     * @request GET:/api/workflowdefinitions/{id}
     * @secure
     */
    workflowdefinitionsDetail: (
      id: string,
      query?: {
        /** @format int32 */
        version?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<Definition, any>({
        path: `/api/workflowdefinitions/${id}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkflowDefinition
     * @name WorkflowdefinitionsCreate
     * @request POST:/api/workflowdefinitions/{id}
     * @secure
     */
    workflowdefinitionsCreate: (id: string, data: Definition, params: RequestParams = {}) =>
      this.request<Definition, any>({
        path: `/api/workflowdefinitions/${id}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkflowDefinition
     * @name WorkflowdefinitionsUpdate
     * @request PUT:/api/workflowdefinitions/{id}
     * @secure
     */
    workflowdefinitionsUpdate: (
      id: string,
      data: Definition,
      query?: {
        workflowId?: string;
        /** @format int32 */
        version?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<Definition, any>({
        path: `/api/workflowdefinitions/${id}`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  workflowExceptions = {
    /**
     * No description
     *
     * @tags WorkflowException
     * @name WorkflowExceptionsList
     * @request GET:/api/workflow-exceptions
     * @secure
     */
    workflowExceptionsList: (
      query?: {
        /**
         * @format int32
         * @min 1
         * @max 2147483647
         */
        page?: number;
        /**
         * @format int32
         * @min 1
         * @max 100
         */
        pageSize?: number;
        orderBys?: OrderBy[];
        /** @format uuid */
        workflowInstanceId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<WorkflowExceptionDto[], any>({
        path: `/api/workflow-exceptions`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkflowException
     * @name WorkflowExceptionsDetail
     * @request GET:/api/workflow-exceptions/{id}
     * @secure
     */
    workflowExceptionsDetail: (id: string, params: RequestParams = {}) =>
      this.request<WorkflowExceptionDto, any>({
        path: `/api/workflow-exceptions/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
}
