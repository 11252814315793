import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { useApiStore } from "./api";
import { MeDto, UserDto, TenantDto, AccountDto } from "@/types/api.js";

export const useSelfStore = defineStore('selfStore', () => {
  const user = ref<UserDto | null>(null);
  const account = ref<AccountDto | null>(null);
  const tenant = ref<TenantDto | null>(null);
  const me = ref<MeDto | null>(null);

  const userSetCallbacks = [] as Function[];
  
  const name = () => {
    return account.value?.name
  }

  const organizationName = () => {
    return tenant.value?.name || '-no organization-';
  }

  const onUserSet = (callback: Function) => {
    userSetCallbacks.push(callback);
  }

  const isUserSet = () => (user.value && user.value.id);
  const hasOrganization = () => (tenant.value && tenant.value.id);
  const showOrgSwitcher = computed<boolean>(() => false);

  const refreshUser = async () => 
  {
    const apiStore = useApiStore();
    try {
      const predefinedOrg = localStorage.getItem('organization_id');
      if (predefinedOrg) {
        apiStore.setTenantId(predefinedOrg);
      }
      let resp = await apiStore.api.me.getMe();
      if (resp && resp.data) {
        const raw = resp.data;
        user.value = raw.user as UserDto
        if (userSetCallbacks.length) {
          userSetCallbacks.forEach(cb => cb());
        }
        account.value = raw.account as AccountDto
        tenant.value = raw.tenant as TenantDto
        if (raw?.tenant?.id) {
          apiStore.setTenantId(raw?.tenant?.id)
        }
        
      }
    } catch(e) {
      console.debug("Failed to retrieve user account", e);
      throw e;
    }
  }

  return { user, account, name, tenant, isUserSet, refreshUser, showOrgSwitcher, hasOrganization, onUserSet, organizationName }
});