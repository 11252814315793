// Composables
import { createRouter, createWebHistory } from 'vue-router'
import { useSelfStore } from '@/store/self'
import { useApiStore } from '@/store/api'
import * as Sentry from "@sentry/browser";
import { Google } from '@/utils/tracking';
import DefaultLayout from '@/layouts/DefaultLayout.vue'

// https://itnext.io/3-ways-to-create-a-multiple-layouts-system-vue-3-b24c0736b7bb

const routes = [
  {
    path: '/',
    name: 'home',
    meta: { 
      layout: DefaultLayout
    },
    component: () => import('@/components/home/HomeDashboard.vue')
  },
  {
    path: '/apps',
    name: 'apps-list',
    meta: { 
      layout: DefaultLayout
    },
    component: () => import('@/components/apps/AppsList.vue'),
  },
  {
    path: '/apps/:id',
    name: 'apps-edit',
    meta: { 
      layout: DefaultLayout
    },
    component: () => import('@/components/apps/AppsEdit.vue'),
  },
  {
    path: '/apps/:appId/connections/:id',
    name: 'connections-edit',
    meta: { 
      layout: DefaultLayout
    },
    component: () => import('@/components/connections/ConnectionsEdit.vue'),
  },
  {
    path: '/workflows',
    name: 'workflows-list',
    meta: { 
      layout: DefaultLayout
    },
    component: () => import('@/components/workflows/WorkflowsList.vue'),
  },
  {
    path: '/apps/:appId/workflows/:id',
    props: true,
    name: 'workflows-edit',
    meta: { 
      layout: DefaultLayout
    },
    component: () => import('@/components/workflows/WorkflowsEdit.vue'),
  },
  {
    path: '/errors',
    name: 'errors-list',
    meta: { 
      layout: DefaultLayout
    },
    component: () => import('@/components/errors/ErrorsList.vue'),
  },
  {
    path: '/errors/:id',
    name: 'errors-edit',
    meta: { 
      layout: DefaultLayout
    },
    component: () => import('@/components/errors/ErrorsEdit.vue'),
  },
  {
    path: '/systems',
    name: 'systems-list',
    meta: { 
      layout: DefaultLayout
    },
    component: () => import('@/components/systems/SystemsList.vue'),
  },
  {
    path: '/systems/:id',
    name: 'systems-edit',
    meta: { 
      layout: DefaultLayout
    },
    component: () => import('@/components/systems/SystemsEdit.vue'),
  },
  {
    path: '/settings',
    name: 'settings',
    meta: { 
      layout: DefaultLayout
    },
    component: () => import('@/components/settings/SettingsEdit.vue'),
  },
  {
    path: '/callback',
    redirect: (to : any) => {
      return { path: '/' }
    }
  },
  {
    path: '/token',
    // somewhat unnecessary, we don't actually render this, we just
    // catch it in the guard below
    component: () => import('@/views/Unauthorized.vue')
  },
  {
    path: '/logout',
    name: 'logout',
    redirect: (to : any) => {
      return { path: '/' }
    }
  },
  {
    path: '/unauthorized',
    name: 'unauthorized',
    component: () => import('@/views/Unauthorized.vue')
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  strict: true,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})

router.beforeEach(async (to, from) => {
  const selfStore = useSelfStore();
  const apiStore = useApiStore();
  if (!selfStore.isUserSet()) {
    if (to.path == '/token') {
      // TODO: store the token in cache? what's our expiration?
      apiStore.setToken(JSON.parse(to.query.tokenJson as string))
      await selfStore.refreshUser()
      if (localStorage.getItem('login_redirect')) {
        var value = localStorage.getItem('login_redirect')
        localStorage.removeItem('login_redirect')
        window.location.href = value!
      } else {
        return { name: 'home' }
      }
    } else if (apiStore.getToken()) {
      // got a token; if it's expired, we'll get a 401 and redirect to /token automatically
      await selfStore.refreshUser()
      return true
    } else {
      // no token, just go to login
      window.setTimeout(() =>{
        window.location.href = import.meta.env.VITE_API_ROOT + '/api/authentication/login'
      }, 1)
      return false
    }
  }
})

router.onError((error, to) => {
  if (
      error.message.includes("Failed to fetch dynamically imported module") ||
      error.message.includes("Failed to load module script")
  ) {
      window.location.reload();
  }
});
/*router.beforeResolve(async (to, from) => {
  const selfStore = useSelfStore();
  if (!selfStore.isUserSet()) {
    try {
      await selfStore.refreshUser();
    } catch(e) {
      window.location.href = import.meta.env.VITE_API_ROOT + '/api/authentication/login'
      
    }
  }
  
})

router.afterEach((to, from) => {
  const selfStore = useSelfStore();
  if (selfStore.isUserSet()) {
    Google.set({ 
      app_title: APP_NAME,
      page_path: to.path,
      page_name: to.name,
      screen_name: to.name,
      envrionment: NODE_ENV,
      release: APP_VERSION,
      user_id: selfStore.user.id,
      organizationId: selfStore.organization.id
    });
    Sentry.setUser(selfStore.user);
    Sentry.setContext("user", selfStore.user);
    Sentry.setContext("organization", selfStore.organization);
    Sentry.setContext("account", selfStore.account);
  }
})
router.onError((error, to) => {
  try {
    Sentry.captureException(error, {
        tags: {
            file: 'router/index.ts'
        },
        extra: {
            to: to
        }
    });
    Google.pushEvent("router_error", {
      error_message: error?.message
    });
  } catch(e) {
    console.error(e)
  }
})*/
export default router
